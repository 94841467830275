import React, { useContext } from "react";
import { AppContext } from "./context/context";

import LumalindawWallpaper from "./assets/Wallpapers/Lumalindaw.jpg"
import TaguwasiWallpaper from "./assets/Wallpapers/TaguwasiWP.jpg"
import UrdujaWallpaper from "./assets/Wallpapers/UrdujaPCWallpaper.jpg"
import TalaWallpaper from "./assets/Wallpapers/TalaWP.jpg"
import Lamang2Wallpaper from "./assets/Wallpapers/LA2Wallpaper.jpg"
import KanibusananWallpaper from "./assets/Wallpapers/KanibusananWP.jpg"
const wallpapersData = [
    {
        id: 1,
        title: "Lumalindaw",
        imageUrl: LumalindawWallpaper,
        downloadUrl: LumalindawWallpaper,
    },
    {
        id: 2,
        title: "Taguwasi",
        imageUrl: TaguwasiWallpaper,
        downloadUrl: TaguwasiWallpaper,
    },
    {
        id: 3,
        title: "Urduja",
        imageUrl: UrdujaWallpaper,
        downloadUrl: UrdujaWallpaper,
    },
    {
        id: 4,
        title: "Tala",
        imageUrl: TalaWallpaper,
        downloadUrl: TalaWallpaper,
    },
    {
        id: 5,
        title: "LamAng 2",
        imageUrl: Lamang2Wallpaper,
        downloadUrl: Lamang2Wallpaper,
    },
    {
        id: 6,
        title: "Kanibusanan",
        imageUrl: KanibusananWallpaper,
        downloadUrl: KanibusananWallpaper,
    },
];

const Wallpapers = () => {
    const { theme } = useContext(AppContext); // Optional: Fetch theme or app context if needed

    return (
        <div style={{ padding: "20px",}}>
            <div className="font-maragsa font-bold pb-2 text-3xl tracking-[3px] text-black uppercase text-center">
                Alamat Series Wallpapers
            </div>
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                    gap: "20px",
                }}
            >
                {wallpapersData.map((wallpaper) => (
                    <div
                        className="paper-bg"
                        key={wallpaper.id}
                        style={{
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            overflow: "hidden",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#fff",
                        }}
                    >
                        <img
                            src={wallpaper.imageUrl}
                            alt={wallpaper.title}
                            style={{ width: "100%", height: "auto" }}
                        />
                        <div className="flex flex-col items-center" style={{ padding: "10px" }}>
                            <h3 className="font-maragsa mb-2 text-xl tracking-[3px]">{wallpaper.title}</h3>
                            <a
                                href={wallpaper.downloadUrl}
                                download
                                style={{
                                    textDecoration: "none",
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    padding: "10px 15px",
                                    borderRadius: "4px",
                                    display: "inline-block",
                                }}
                            >
                                Download
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Wallpapers;
