import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link, Outlet, useLocation } from 'react-router-dom';
import { AppContext } from "./context/context";

import Author from './Author.js';
import Books from './Books.js';
import Home from './Home.js';
import Maps from './Maps.js';
import Wallpapers from "./wallpapers.js";
import BooksPage from "./BooksPage";
import Footer from "./Footer";

import NavBar from "./Navbar.js";
import { useState } from "react";
import Announcement from "./Announcement";


function App() {
  const [currentBook, setCurrentBook] = useState({})
  const [bookNumber, setBookNumber] = useState(0);
  const [stars, setStars] = useState('5')
  return (
    
      <AppContext.Provider value={{currentBook, setCurrentBook, bookNumber, setBookNumber, stars, setStars}}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="books" element={<Books />} />
              <Route path="books/*" element={<BooksPage />} />
              <Route path="author" element={<Author />} />
              <Route path="maps" element={<Maps />} />
              <Route path="wallpapers" element={<Wallpapers />}/>

              {/* Using path="*"" means "match anything", so this route
                    acts like a catch-all for URLs that we don't have explicit
                    routes for. */}
              {/* <Route path="*" element={<NoMatch />} /> */}
            </Route>
          </Routes>
        </Router>
      </AppContext.Provider>
    
  );
}

function Layout() {
  const location = useLocation()
  const showAnnouncement = location.pathname === '/'
  return (
    <div>
    { showAnnouncement && <Announcement /> }
      <div className="flex flex-col h-screen p-0 relative w-full">
        <NavBar/>
        <div className="bg-white flex-1">
          <Outlet />
        </div>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
