import React from "react";

import Rattan from "./assets/flipPages.png"

import LamangCover from './assets/lamang-cover.png'
import Lamang1 from './assets/lamang-maps/01_Samtoy_Nation.jpg'
import Lamang2 from './assets/lamang-maps/02_Journey_North.jpg'
import Lamang3 from './assets/lamang-maps/03_Daquel_a_Danum.jpg'
import Lamang4 from './assets/lamang-maps/04_Sailing_the_Western_Seas.jpg'
import Lamang5 from './assets/lamang-maps/05_Natirad_ti_Bato.jpg'
import Lamang6 from './assets/lamang-maps/06_Sailing_up_the_Cagayan_River.jpg'
import Lamang7 from './assets/lamang-maps/07_Sarangay_the_Anggitay_in_Garao.jpg'
import Lamang8 from './assets/lamang-maps/08_The_Northern_Sea_and_Fuga_Island.jpg'
import Lamang9 from './assets/lamang-maps/09_Return_to_Nalbuan.jpg'
import Lamang10 from './assets/lamang-maps/10_Dagman.jpg'
import Lamang11 from './assets/lamang-maps/11_Dagman_to_the_Bakong_Rice_Fields.jpg'
import Lamang12 from './assets/lamang-maps/12_Bakong_Rice_Fields_to_Gambang.jpg'
import Lamang13 from './assets/lamang-maps/13_Pandan.jpg'
import Lamang14 from './assets/lamang-maps/14_Dardarat.jpg'
import Lamang15 from './assets/lamang-maps/15_Sarimaw.jpg'


import LumalindawCover from './assets/lumalindaw-cover.png' 
import Lumalindaw1 from './assets/lumalindaw-maps/01_Ga_dang_Highlands.jpg'
import Lumalindaw2 from './assets/lumalindaw-maps/02_Cagayan_Valley.jpg'
import Lumalindaw3 from './assets/lumalindaw-maps/03_Icawayan.jpg'
import Lumalindaw4 from './assets/lumalindaw-maps/04_Battle_of_Icawayan.jpg'
import Lumalindaw5 from './assets/lumalindaw-maps/05_Aound_Icawayan.jpg'
import Lumalindaw6 from './assets/lumalindaw-maps/06_Caravan_to_Icawayan.jpg'
import Lumalindaw7 from './assets/lumalindaw-maps/07_Kalekay.jpg'
import Lumalindaw8 from './assets/lumalindaw-maps/08_Kidul_s_Tale.jpg'
import Lumalindaw9 from './assets/lumalindaw-maps/09_Search_for_AD.jpg'
import Lumalindaw10 from './assets/lumalindaw-maps/10_Nabuangan_Forest.jpg'
import Lumalindaw11 from './assets/lumalindaw-maps/11_Battle_of_Nabuangan_Forest_1.jpg'
import Lumalindaw12 from './assets/lumalindaw-maps/12_Battle_of_Nabuangan_Forest_2.jpg'
import Lumalindaw13 from './assets/lumalindaw-maps/13_Battle_of_Nabuangan_Forest_3.jpg'
import Lumalindaw14 from './assets/lumalindaw-maps/14_Battle_of_Nabuangan_Forest_4.jpg'
import Lumalindaw15 from './assets/lumalindaw-maps/15_Battle_of_Nabuangan_Forest_5.jpg'
import Lumalindaw16 from './assets/lumalindaw-maps/16_Battle_of_Nabuangan_Forest_6.jpg'
import Lumalindaw17 from './assets/lumalindaw-maps/17_The_Dalaketnon.jpg'
import Lumalindaw18 from './assets/lumalindaw-maps/18_Iquiladan_Hut.jpg'
import Lumalindaw19 from './assets/lumalindaw-maps/19_The_Cave.jpg'
import Lumalindaw20 from './assets/lumalindaw-maps/20_Arrival.jpg'
import Lumalindaw21 from './assets/lumalindaw-maps/21_The_Hidden_Cave.jpg'
import Lumalindaw22 from './assets/lumalindaw-maps/22_Gampao.jpg'

import TaguwasiCover from './assets/taguwasi-cover-basic.png'
import Taguwasi1 from './assets/taguwasi-maps/01_Gampao.png'
import Taguwasi2 from './assets/taguwasi-maps/02_The_Balete_Tree.png'
import Taguwasi3 from './assets/taguwasi-maps/03_Garao_to_Gabur_Falls.png'
import Taguwasi4 from './assets/taguwasi-maps/04_Kuta_Salakan.png'
import Taguwasi5 from './assets/taguwasi-maps/05_Moving.png'
import Taguwasi6 from './assets/taguwasi-maps/06_Camiguin_Island.png'
import Taguwasi7 from './assets/taguwasi-maps/07_Ambush.png'
import Taguwasi8 from './assets/taguwasi-maps/08_Rescue.png'
import Taguwasi9 from './assets/taguwasi-maps/09_The_Lagoon.png'
import Taguwasi10 from './assets/taguwasi-maps/10_Dalupiri_Island.png'
import Taguwasi11 from './assets/taguwasi-maps/11_Ammalabukaw.png'
import Taguwasi12 from './assets/taguwasi-maps/12_Dalawesan_s_Escape.png'
import Taguwasi13 from './assets/taguwasi-maps/13_Dalawesan_s_Search.png'
import Taguwasi14 from './assets/taguwasi-maps/14_Epilogue.png'

import UrdujaCover from './assets/urduja-cover.png' 
import Urduja1 from './assets/urduja-maps/01_Kaboloan.png'
import Urduja2 from './assets/urduja-maps/02_Bantayan_Port.png'
import Urduja3 from './assets/urduja-maps/03_Binalatongan.png'
import Urduja4 from './assets/urduja-maps/04_Pasol.png'
import Urduja5 from './assets/urduja-maps/05_Bolinao.png'
import Urduja6 from './assets/urduja-maps/06_Belete_Tree_in_Bolinao.png'
import Urduja7 from './assets/urduja-maps/07_Return_to_Bolinao.png'
import Urduja8 from './assets/urduja-maps/08_Tarlac.png'
import Urduja9 from './assets/urduja-maps/09_Nangkayang.png'
import Urduja10 from './assets/urduja-maps/10_Mount_Pinatubo.png'
import Urduja11 from './assets/urduja-maps/11_Bakunawa.png'
import Urduja12 from './assets/urduja-maps/12_The_Sambal_Lands.png'
import Urduja13 from './assets/urduja-maps/13_Kaalogan_A.png'
import Urduja14 from './assets/urduja-maps/14_Kaalogan_B.png'
import Urduja15 from './assets/urduja-maps/15_Retaking_Binalatongan.png'
import Urduja16 from './assets/urduja-maps/16_Battle_of_Bayambang_A.png'
import Urduja17 from './assets/urduja-maps/17_Battle_of_Bayambang_B.png'
import Urduja18 from './assets/urduja-maps/18_Battle_of_Bayambang_C.png'
import Urduja19 from './assets/urduja-maps/19_Gongat.png'
import Urduja20 from './assets/urduja-maps/20_Butao.png'
import Urduja21 from './assets/urduja-maps/21_Dagupan.png'
import Urduja22 from './assets/urduja-maps/22_Battle_of_Binalatongan_A.png'
import Urduja23 from './assets/urduja-maps/23_Battle_of_Binalatongan_B.png'
import Urduja24 from './assets/urduja-maps/24_Battle_of_Binalatongan_C.png'
import Urduja25 from './assets/urduja-maps/25_Battle_of_Binalatongan_D.png'
import Urduja26 from './assets/urduja-maps/26_Quanzhou_Port.png'

import TalaCover from './assets/tala-cover.jpg'
import Tala1 from './assets/tala-maps/01_The_Tagalog_Lands.jpg'
import Tala2 from './assets/tala-maps/02_Talin_Island.jpg'
import Tala3 from './assets/tala-maps/03_Tayuman.jpg'
import Tala4 from './assets/tala-maps/04_The_Sangkawan_A.jpg'
import Tala5 from './assets/tala-maps/05_The_Sangkawan_B.jpg'
import Tala6 from './assets/tala-maps/06_The Sangkawan_C.jpg'
import Tala7 from './assets/tala-maps/07_The_Tipolo_Massacre.jpg'
import Tala8 from './assets/tala-maps/08_Return_to_Tayuman.jpg'
import Tala9 from './assets/tala-maps/09_Coming_Home.jpg'
import Tala10 from './assets/tala-maps/10_Lambak_Sailing_West.jpg'
import Tala11 from './assets/tala-maps/11_Muntinlupa.jpg'
import Tala12 from './assets/tala-maps/12_Kabuyao.jpg'
import Tala13 from './assets/tala-maps/13_Tangway.jpg'
import Tala14 from './assets/tala-maps/14_Mount_Palay.jpg'
import Tala15 from './assets/tala-maps/15_The_Journey_West.jpg'
import Tala16 from './assets/tala-maps/16_The_Bayan_of_Selurong.jpg'
import Tala17 from './assets/tala-maps/17_The_Balete_Tree_and_The_Gisurab.jpg'
import Tala18 from './assets/tala-maps/18_The_Gubunans_Nest.jpg'
import Tala19 from './assets/tala-maps/19_Matungao.jpg'
import Tala20 from './assets/tala-maps/20_Bukawe.jpg'
import Tala21 from './assets/tala-maps/21_The_Aeta_Settlement.jpg'
import Tala22 from './assets/tala-maps/22_The_Defense_of_Selurong.jpg'
import Tala23 from './assets/tala-maps/23_The_Battle_of_Selurong.jpg'
import Tala24 from './assets/tala-maps/24_Buhongs_Hut.jpg'
import Tala25 from './assets/tala-maps/25_The_Kingdom_of_Kaboloan.jpg'

import lamang2Cover from './assets/lamang2_cover.jpg'
import lamang2_1 from './assets/lamang2-maps/01_Nalbuan_The_Samtoy_Nation.jpg'
import lamang2_2 from './assets/lamang2-maps/02_Ginhanan.jpg'
import lamang2_3 from './assets/lamang2-maps/03_The_Balete_Tree_Nalbuan.jpg'
import lamang2_4 from './assets/lamang2-maps/04_Kasakitan.jpg'
import lamang2_5 from './assets/lamang2-maps/05_Ganagan.jpg'
import lamang2_6 from './assets/lamang2-maps/06_Intar.jpg'
import lamang2_7 from './assets/lamang2-maps/07_Daadam.jpg'
import lamang2_8 from './assets/lamang2-maps/08_Kalanutian.jpg'
import lamang2_9 from './assets/lamang2-maps/09_Sea_Monsters.jpg'
import lamang2_10 from './assets/lamang2-maps/10_The_Lalad.jpg'
import lamang2_11 from './assets/lamang2-maps/11_A_Long_Awaited_Fight.jpg'
import lamang2_12 from './assets/lamang2-maps/12_Love_and_Hate.jpg'
import lamang2_13 from './assets/lamang2-maps/13_Duwendes,_Kirbas,_and_ Gold.jpg'
import lamang2_14 from './assets/lamang2-maps/14_The_Maneater.jpg'
import lamang2_15 from './assets/lamang2-maps/15_The_Mandarangkal_Attacks.jpg'
import lamang2_16 from './assets/lamang2-maps/16_Badoc_Island.jpg'
import lamang2_17 from './assets/lamang2-maps/17_The_Berbakan_Returns.jpg'
import lamang2_18 from './assets/lamang2-maps/18_A_Massacre_in_the_Mountains.jpg'

import KanibusananCover from './assets/Kanibusanan_Book_Cover.jpg' 
import Kanibusanan1 from './assets/Kanibusanan-maps/01_Nalbuan_The_ Samtoy_Nation.png'
import Kanibusanan2 from './assets/Kanibusanan-maps/02_Returning_to_the_Settlement.png'
import Kanibusanan3 from './assets/Kanibusanan-maps/03_The_Gampao_Eruption.png'
import Kanibusanan4 from './assets/Kanibusanan-maps/04_Binalatongan_Kaboloan.png'
import Kanibusanan5 from './assets/Kanibusanan-maps/05_Hannanga.png'
import Kanibusanan6 from './assets/Kanibusanan-maps/06_Return_to_Icawayan.png'
import Kanibusanan7 from './assets/Kanibusanan-maps/07_Return_to_Pinatubo.png'
import Kanibusanan8 from './assets/Kanibusanan-maps/08_Sarimaw_Kasanaan.png'
import Kanibusanan9 from './assets/Kanibusanan-maps/09_Flight_to_Binalatongan.png'
import Kanibusanan10 from './assets/Kanibusanan-maps/10_Assault_on_Garao.png'
import Kanibusanan11 from './assets/Kanibusanan-maps/11_Battle_of_Garao.png'
import Kanibusanan12 from './assets/Kanibusanan-maps/12_East_of_Kaboloan.png'
import Kanibusanan13 from './assets/Kanibusanan-maps/13_Battle_of_Kaboloan_1.png'
import Kanibusanan14 from './assets/Kanibusanan-maps/14_Battle_of_Kaboloan_2.png'
import Kanibusanan15 from './assets/Kanibusanan-maps/15_Battle_of_Kaboloan_3.png'
import Kanibusanan16 from './assets/Kanibusanan-maps/16_Battle_of_Kaboloan_4.png'
import Kanibusanan17 from './assets/Kanibusanan-maps/17_Battle_of_Kaboloan_5.png'
import Kanibusanan18 from './assets/Kanibusanan-maps/18_Battle_of_Kaboloan_6.png'
import Kanibusanan19 from './assets/Kanibusanan-maps/19_Northern_Kingdoms_Kaluwalhatian.png'
import Kanibusanan20 from './assets/Kanibusanan-maps/20_Hulugan_Falls.png'
import Kanibusanan21 from './assets/Kanibusanan-maps/21_Banahaw_Eruption.png'
import Kanibusanan22 from './assets/Kanibusanan-maps/22_Grand_Army_of_Tawalisi.png'
import Kanibusanan23 from './assets/Kanibusanan-maps/23_Barangay_Luta.png'
import Kanibusanan24 from './assets/Kanibusanan-maps/24_Luta_Raids_1.png'
import Kanibusanan25 from './assets/Kanibusanan-maps/25_Luta_Raids_2.png'
import Kanibusanan26 from './assets/Kanibusanan-maps/26_Luta_Raids_3.png'
import Kanibusanan27 from './assets/Kanibusanan-maps/27_Assault_on_Luta 1.png'
import Kanibusanan28 from './assets/Kanibusanan-maps/28_Assault_on_Luta 2.png'
import Kanibusanan29 from './assets/Kanibusanan-maps/29_Arrival.png'
import Kanibusanan30 from './assets/Kanibusanan-maps/30_Master_Plan.png'
import Kanibusanan31 from './assets/Kanibusanan-maps/31_Battle_of_Bombón_Lake 1.png'
import Kanibusanan32 from './assets/Kanibusanan-maps/32_Battle_of_Bombón_Lake 2.png'
import Kanibusanan33 from './assets/Kanibusanan-maps/33_Battle_of_Bombón_Lake 3.png'
import Kanibusanan34 from './assets/Kanibusanan-maps/34_Battle_of_Bombón_Lake 4.png'
import Kanibusanan35 from './assets/Kanibusanan-maps/35_Battle_of_Bombón_Lake 5.png'
import Kanibusanan36 from './assets/Kanibusanan-maps/36_Battle_of_Bombón_Lake 6.png'
import Kanibusanan37 from './assets/Kanibusanan-maps/37_Clash_at_Mount_Bombou.png'

export default {
    maps: [
        {
            title: "Lam-Ang",
            bookImages: [{image:Rattan, title:''} , 
            {image:LamangCover, title:'Cover'}, 
            {image:Lamang1, title:'Samtoy Nation'}, 
            {image:Lamang2, title:'Journey North'},
            {image:Lamang3, title:'Daquel a Danum'},
            {image:Lamang4, title:'Sailing the Western Seas'},
            {image:Lamang5, title:'Natirad ti Bato'},
            {image:Lamang6, title:'Sailing up the Cagayan River'},
            {image:Lamang7, title:'Sarangay the Anggitay in Garao'},
            {image:Lamang8, title:'The Northern Sea and Fuga Island'},
            {image:Lamang9, title:'Return to Nalbuan'},
            {image:Lamang10, title:'Dagman'},
            {image:Lamang11, title:'Dagman to the Bakong Rice Fields'},
            {image:Lamang12, title:'Bakong Rice Fields to Gambang'},
            {image:Lamang13, title:'Pandan'},
            {image:Lamang14, title:'Dardarat'},
            {image:Lamang15, title:'Sarimaw'},
            {image:Rattan, title:''}]
        },
        {
            title: "Lumalindaw",
            bookImages: [{image:Rattan, title:''} , 
            {image:LumalindawCover, title:'Cover'}, 
            {image:Lumalindaw1, title:'Ga_Dang Highlands'}, 
            {image:Lumalindaw2, title:'Cagayan Valley'},
            {image:Lumalindaw3, title:'Icawayan'},
            {image:Lumalindaw4, title:'Battle of Icawayan'},
            {image:Lumalindaw5, title:'Around Icawayan'},
            {image:Lumalindaw6, title:'Caravan to Icawayan'},
            {image:Lumalindaw7, title:'Kalekay'},
            {image:Lumalindaw8, title:"Kidul's Tale"},
            {image:Lumalindaw9, title:'Search for AD'},
            {image:Lumalindaw10, title:'Nabuangan Forest'},
            {image:Lumalindaw11, title:'Battle of Nabuangan Forest I'},
            {image:Lumalindaw12, title:'Battle of Nabuangan Forest II'},
            {image:Lumalindaw13, title:'Battle of Nabuangan Forest III'},
            {image:Lumalindaw14, title:'Battle of Nabuangan Forest IV'},
            {image:Lumalindaw15, title:'Battle of Nabuangan Forest V'},
            {image:Lumalindaw16, title:'Battle of Nabuangan Forest VI'},
            {image:Lumalindaw17, title:'The Dalaketnon'},
            {image:Lumalindaw18, title:'Iquiladan Hut'},
            {image:Lumalindaw19, title:'The Cave'},
            {image:Lumalindaw20, title:'Arrival'},
            {image:Lumalindaw21, title:'The Hidden Cave'},
            {image:Lumalindaw22, title:'Gampao'},
            {image:Rattan, title:''}]
        },
        {
            title: "Taguwasi",
            bookImages: [{image:Rattan, title:''},
            {image:TaguwasiCover, title:'Cover'}, 
            {image:Taguwasi1, title:'Gampao'}, 
            {image:Taguwasi2, title:'The Balete Tree'},
            {image:Taguwasi3, title:'Garao to Gabur Falls'},
            {image:Taguwasi4, title:'Kita Salakan'},
            {image:Taguwasi5, title:'Moving'},
            {image:Taguwasi6, title:'Camiguin Island'},
            {image:Taguwasi7, title:'Ambush'},
            {image:Taguwasi8, title:"Rescue"},
            {image:Taguwasi9, title:'The Lagoon'},
            {image:Taguwasi10, title:'Dlupiri Island'},
            {image:Taguwasi11, title:'Ammalabukaw'},
            {image:Taguwasi12, title:"Dalawesan's Escape"},
            {image:Taguwasi13, title:"Dalawesan's Rescue"},
            {image:Taguwasi14, title:'Epilogue'},
            {image:Rattan, title:''}]
        },
        {
            title: "Urduja",
            bookImages: [{image:Rattan, title:''} , 
            {image:UrdujaCover, title:'Cover'}, 
            {image:Urduja1, title:'Kaboloan'}, 
            {image:Urduja2, title:'Bantayan Port'},
            {image:Urduja3, title:'Binalatongan'},
            {image:Urduja4, title:'Pasol'},
            {image:Urduja5, title:'Bolinao'},
            {image:Urduja6, title:'Belete Tree in Bolinao'},
            {image:Urduja7, title:'Return to Bolinao'},
            {image:Urduja8, title:"Tarlac"},
            {image:Urduja9, title:'Nangkayang'},
            {image:Urduja10, title:'Mount Pinatubo'},
            {image:Urduja11, title:'Bakunawa'},
            {image:Urduja12, title:'The Sambal Lands'},
            {image:Urduja13, title:'Kaalogan A'},
            {image:Urduja14, title:'Kaalogan B'},
            {image:Urduja15, title:'Retaking Binalatongan'},
            {image:Urduja16, title:'Battle of Bayambang A'},
            {image:Urduja17, title:'Battle of Bayambang B'},
            {image:Urduja18, title:'Battle of Bayambang C'},
            {image:Urduja19, title:'Gongat'},
            {image:Urduja20, title:'Butao'},
            {image:Urduja21, title:'Dagupan'},
            {image:Urduja22, title:'Battle of Binalatongan A'},
            {image:Urduja23, title:'Battle of Binalatongan B'},
            {image:Urduja24, title:'Battle of Binalatongan C'},
            {image:Urduja25, title:'Battle of Binalatongan D'},
            {image:Urduja26, title:'Quanzhou Port'},
            {image:Rattan, title:''}]
        },
        {
            title: "Tala",
            bookImages: [{image:Rattan, title:''} , 
            {image:TalaCover, title:'Cover'}, 
            {image:Tala1, title:'The Tagalog Lands'}, 
            {image:Tala2, title:'Talim Island'},
            {image:Tala3, title:'Tayuman'},
            {image:Tala4, title:'The Sangkawan A'},
            {image:Tala5, title:'The Sangkawan B'},
            {image:Tala6, title:'The Sangkawan C'},
            {image:Tala7, title:'The Tipolo Massacre'},
            {image:Tala8, title:"Return to Tayuman"},
            {image:Tala9, title:'Coming Home'},
            {image:Tala10, title:'Lambak Sailing West'},
            {image:Tala11, title:'Muntinlupa'},
            {image:Tala12, title:'Kabuyao'},
            {image:Tala13, title:'Tangway'},
            {image:Tala14, title:'Mount Palay'},
            {image:Tala15, title:'The Journey West'},
            {image:Tala16, title:'The Bayan of Selurong'},
            {image:Tala17, title:'The Balete Tree and The Gisurab'},
            {image:Tala18, title:'The Gubunans Nest'},
            {image:Tala19, title:'Matungao'},
            {image:Tala20, title:'Bukawe'},
            {image:Tala21, title:'The Aeta Settlement'},
            {image:Tala22, title:'The Defense of Serulong'},
            {image:Tala23, title:'The Battle of Serulong'},
            {image:Tala24, title:'Buhongs Hut'},
            {image:Tala25, title:'The Kingdom of Kaboloan'},
            {image:Rattan, title:''}]
        },
        {
            title: "Lam-Ang 2",
            bookImages: [{image:Rattan, title:''} , 
            {image:lamang2Cover, title:'Cover'}, 
            {image:lamang2_1, title:'Nalbuan The Samtoy Nation'}, 
            {image:lamang2_2, title:'Ginhanan'},
            {image:lamang2_3, title:'The Balete Tree Nalbuan'},
            {image:lamang2_4, title:'Kasakitan'},
            {image:lamang2_5, title:'Ganagan'},
            {image:lamang2_6, title:'Intar'},
            {image:lamang2_7, title:'Daadam'},
            {image:lamang2_8, title:"Kalanutian"},
            {image:lamang2_9, title:'Sea Monsters'},
            {image:lamang2_10, title:'The Lalad'},
            {image:lamang2_11, title:'A Long Awaited Fight'},
            {image:lamang2_12, title:'Love and Hate'},
            {image:lamang2_13, title:'Duwendes, Kiribas, and Gold'},
            {image:lamang2_14, title:'The Maneater'},
            {image:lamang2_15, title:'The Mandarangkal Attacks'},
            {image:lamang2_16, title:'Badoc Island'},
            {image:lamang2_17, title:'The Berbakan Returns'},
            {image:lamang2_18, title:'A Massacre in the Mountains'},
            {image:Rattan, title:''}]
        },
        {            title: "Urduja",
            bookImages: [{image:Rattan, title:''} , 
            {image:KanibusananCover, title:'Cover'}, 
            {image:Kanibusanan1, title:'Nalbuan The Samtoy Nation'}, 
            {image:Kanibusanan2, title:'Returning to the Settlement'},
            {image:Kanibusanan3, title:'The Gampao Eruption'},
            {image:Kanibusanan4, title:'Binalatongan Kaboloan'},
            {image:Kanibusanan5, title:'Hannanga'},
            {image:Kanibusanan6, title:'Return to Icawayan'},
            {image:Kanibusanan7, title:'Return to Pinatubo'},
            {image:Kanibusanan8, title:"Sarimaw Kasanaan"},
            {image:Kanibusanan9, title:'NangkayangFlight to Binalatongan'},
            {image:Kanibusanan10, title:'Assult on Garao'},
            {image:Kanibusanan11, title:'Battle of Garao'},
            {image:Kanibusanan12, title:'East of Kaboloan'},
            {image:Kanibusanan13, title:'Battle of Kaboloan 1'},
            {image:Kanibusanan14, title:'Battle of Kaboloan 2'},
            {image:Kanibusanan15, title:'Battle of Kaboloan 3'},
            {image:Kanibusanan16, title:'Battle of Kaboloan 4'},
            {image:Kanibusanan17, title:'Battle of Kaboloan 5'},
            {image:Kanibusanan18, title:'Battle of Kaboloan 6'},
            {image:Kanibusanan19, title:'Northern Kingdoms Kaluwalhatian'},
            {image:Kanibusanan20, title:'Hulugan Falls'},
            {image:Kanibusanan21, title:'Banahaw Eruption'},
            {image:Kanibusanan22, title:'Grand Army of Tawalisi'},
            {image:Kanibusanan23, title:'Barangay Luta'},
            {image:Kanibusanan24, title:'Luta Raids 1'},
            {image:Kanibusanan25, title:'Luta Raids 2'},
            {image:Kanibusanan26, title:'Luta Raids 3'},
            {image:Kanibusanan27, title:'Assault on Luta 1'},
            {image:Kanibusanan28, title:'Assault on Luta 2'},
            {image:Kanibusanan29, title:'Arrival'},
            {image:Kanibusanan30, title:'Master Plan'},
            {image:Kanibusanan31, title:'Battle of Bombón Lake 1'},
            {image:Kanibusanan32, title:'Battle of Bombón Lake 2'},
            {image:Kanibusanan33, title:'Battle of Bombón Lake 3'},
            {image:Kanibusanan34, title:'Battle of Bombón Lake 4'},
            {image:Kanibusanan35, title:'Battle of Bombón Lake 5'},
            {image:Kanibusanan36, title:'Battle of Bombón Lake 6'},
            {image:Kanibusanan37, title:'Clash at Mount Bombou'},
            {image:Rattan, title:''}]
        }
      ]
}