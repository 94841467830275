import React, { useContext } from "react";
import { AppContext } from "./context/context";
import MyBook from "./MapsBook";

const Maps = () => {
  const { bookNumber, setBookNumber } = useContext(AppContext);
  const books = [
    "LamAng",
    "Lumalindaw",
    "Taguwasi",
    "Urduja",
    "Tala",
    "Ascension",
    "Kanibusanan",
  ];

  return (
    <div className="h-full flex flex-col items-center rattan-bg justify-center p-4">

      {/* Suggestion 2: Horizontal Scrollable Tabs */}
      <div className="flex overflow-x-scroll scrollbar-hide space-x-4 mb-4">
        {books.map((book, index) => (
          <button
            key={index}
            onClick={() => setBookNumber(index)}
            className={`flex-shrink-0 px-4 py-2 rounded-md font-semibold ${
              bookNumber === index
                ? "bg-green-600 text-white"
                : "bg-gray-200 text-gray-800 hover:bg-green-500 hover:text-white"
            }`}
          >
            {book}
          </button>
        ))}
      </div>


      <MyBook />
    </div>
  );
};

export default Maps;
