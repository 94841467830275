import React from "react";
import ReviewsDB from "./ReviewsDB";

//import LamangCover from "./assets/lamang-cover.png";
import LumalindawCover from "./assets/lumalindaw-cover-1.png";
import Book3Teaser from "./assets/taguwasi-cover.png";
import LamangBook from "./assets/lamang-cover-1.png";
import Book4 from "./assets/urduja.png"
import Book5 from "./assets/tala.png"
import Book6 from "./assets/lamang2-book.png"
import Book7 from "./assets/Kanibusanan_Book_7_Cover.jpg"

const book = ReviewsDB.book;

export default {
  books: [
    {
      title: "Lam-Ang",
      photo: LamangBook,
      shortDescription: "The war of the three realms begins here...",
      description:
        "In the heart of the Samtoy Nation during the pre-colonial era of Philippine history, the world of Filipino heroes, deities and mythological monsters collide in this ambitious re-telling of a classic Filipino epic. Our story begins with Namongan, a healer and midwife from an isolated village in the Ilocos Region who discovers that she is destined to bear a child blessed by the Poon deities. This child is Lam-ang and he is the first Lakandian. Maturing at a rate unheard of amongst mere mortals, Lam-ang possesses the strength of a hundred men and was trained to be the greatest warrior for good the mortal realm has ever seen. Together with Mangmankik, an anito spirit assigned to look after him by the Poons, Lam-ang sets out on a quest to find the missing pieces of the Gayaman Amulet and rescue his father who was abducted by the insidious night creatures on the night that he was born. This book covers the first half of the life of Lam-ang, derived from the Ilocano epic Biag ni Lam-ang. The story follows the adventures of our hero as he uncovers the truth about himself as well as the truth about his role in the war between the forces of good and evil that threatens not just the fate of the world of men but all the realms of the spirit world as well.",
      link: "https://www.amazon.com/Alamat-Book-Lam-ang-Philippine-Mythology/dp/B0B93C1MN9",
      LazadaPBLink:
        "https://www.lazada.com.ph/products/alamat-book-1-lam-ang-philippine-mythology-alamat-book-series-paper-back-cover-i4117039253-s22807834837.html?c=&amp;channelLpJumpArgs=&amp;clickTrackInfo=query%253A%253Bnid%253A4117039253%253Bsrc%253AlazadaInShopSrp%253Brn%253A11fa7ea306c13ab60fd7a46250771f6f%253Bregion%253Aph%253Bsku%253A4117039253_PH%253Bprice%253A650%253Bclient%253Adesktop%253Bsupplier_id%253A500163891472%253Bpromotion_biz%253A%253Basc_category_id%253A6905%253Bitem_id%253A4117039253%253Bsku_id%253A22807834837%253Bshop_id%253A1536852&amp;fastshipping=0&amp;freeshipping=0&amp;fs_ab=2&amp;fuse_fs=&amp;lang=en&amp;location=&amp;price=6.5E%202&amp;priceCompare=skuId%3A22807834837%3Bsource%3Alazada-search-voucher%3Bsn%3A11fa7ea306c13ab60fd7a46250771f6f%3BoriginPrice%3A65000%3BdisplayPrice%3A65000%3BsinglePromotionId%3A-1%3BsingleToolCode%3A-1%3BvoucherPricePlugin%3A0%3Btimestamp%3A1695768575594&amp;ratingscore=0&amp;request_id=11fa7ea306c13ab60fd7a46250771f6f&amp;review=&amp;sale=0&amp;search=1&amp;source=search&amp;spm=a2o4l.store_product.list.i40.651a4887ilQrd4&amp;stock=1",
      LazadaHCLink: 
        "https://www.lazada.com.ph/products/alamat-book-1-lam-ang-philippine-mythology-alamat-book-series-hardcover-i4117053247-s22807957526.html?c=&amp;channelLpJumpArgs=&amp;clickTrackInfo=query%253A%253Bnid%253A4117053247%253Bsrc%253AlazadaInShopSrp%253Brn%253A748afad019e7a702c14562b2e5498646%253Bregion%253Aph%253Bsku%253A4117053247_PH%253Bprice%253A900%253Bclient%253Adesktop%253Bsupplier_id%253A500163891472%253Bpromotion_biz%253A%253Basc_category_id%253A6919%253Bitem_id%253A4117053247%253Bsku_id%253A22807957526%253Bshop_id%253A1536852&amp;fastshipping=0&amp;freeshipping=0&amp;fs_ab=2&amp;fuse_fs=&amp;lang=en&amp;location=&amp;price=9E%202&amp;priceCompare=skuId%3A22807957526%3Bsource%3Alazada-search-voucher%3Bsn%3A748afad019e7a702c14562b2e5498646%3BoriginPrice%3A90000%3BdisplayPrice%3A90000%3BsinglePromotionId%3A-1%3BsingleToolCode%3A-1%3BvoucherPricePlugin%3A0%3Btimestamp%3A1695769255886&amp;ratingscore=0&amp;request_id=748afad019e7a702c14562b2e5498646&amp;review=&amp;sale=0&amp;search=1&amp;source=search&amp;spm=a2o4l.store_product.list.i40.651a4887vlc8pj&amp;stock=1" ,
      ShopeePBLink:
        "https://shopee.ph/Alamat-Book-1-Lam-ang-Philippine-Mythology-(Alamat-Book-Series)-PAPER-BACK-COVER-i.45159795.23381125769?xptdk=636dd85f-939e-4b44-ada1-7d9f886b325f",
      ShopeeHCLink: 
        "https://shopee.ph/Alamat-Book-1-Lam-ang-Philippine-Mythology-(Alamat-Book-Series)-Hardcover-i.45159795.23255664815?xptdk=5575389b-a4f7-40f8-a1f7-597ad460a769" ,
      HotTropiksLink:
      "",
      buttonText: "Buy Now",
      bookPage: "/books/LamAng",
      reviewIndex: 0,
    },
    {
      title: "Lumalindaw",
      photo: LumalindawCover,
      shortDescription: "The next Lakandian is here...",
      description:
        "The next Lakandian is here… Somewhere in the Mountain Province amongst the Ga'dang tribes, lies the highland barangay of Nabbobawan. It's in this particular barangay where our story of the Ga'dang epic hero Lumalindaw begins. As is true with all Lakandians, he is but a few years young and a child at heart, but his physique is that of a full-grown man. He has already embraced his role as Lakandian and has been revered as the champion of many tribes and peoples across the Cagayan Valley. Lumalindaw is imbued with the power to summon The Voice of Nanolay, giving him the ability to be heard through vast distances and across the dense jungles and staggering mountains. Its intensity can kill birds in the sky or knock down trees with a word. But destiny awaits him in another barangay where he must face a powerful dalaketnon, a creature of darkness and evil. And in this fateful meeting, he will come face to face with his personal realities as his test of strength intersects with the growing challenges of his evolving character. Accompanying him in this adventure is Kidul, an anito trapped inside an enchanted ayoding, more commonly known as a zither, who helps Lumalindaw navigate his adventures as he, the young warrior is slowly coming into his own. Through these mythical exploits, Lumalindaw will learn what it means to be a man worthy of life, love, and the honor of being a true Lakandian.",
      link: "https://www.amazon.com/Alamat-Book-Lumalindaw-ebook/dp/B0BLXLS7TV?ref_=ast_sto_dp",
      LazadaPBLink:
        "https://www.lazada.com.ph/products/alamat-book-2-lumalindaw-alamat-book-series-paper-back-i4116943938-s22807941000.html?c=&amp;channelLpJumpArgs=&amp;clickTrackInfo=query%253A%253Bnid%253A4116943938%253Bsrc%253AlazadaInShopSrp%253Brn%253A748afad019e7a702c14562b2e5498646%253Bregion%253Aph%253Bsku%253A4116943938_PH%253Bprice%253A650%253Bclient%253Adesktop%253Bsupplier_id%253A500163891472%253Bpromotion_biz%253A%253Basc_category_id%253A6919%253Bitem_id%253A4116943938%253Bsku_id%253A22807941000%253Bshop_id%253A1536852&amp;fastshipping=0&amp;freeshipping=0&amp;fs_ab=2&amp;fuse_fs=&amp;lang=en&amp;location=&amp;price=6.5E%202&amp;priceCompare=skuId%3A22807941000%3Bsource%3Alazada-search-voucher%3Bsn%3A748afad019e7a702c14562b2e5498646%3BoriginPrice%3A65000%3BdisplayPrice%3A65000%3BsinglePromotionId%3A-1%3BsingleToolCode%3A-1%3BvoucherPricePlugin%3A0%3Btimestamp%3A1695769255886&amp;ratingscore=0&amp;request_id=748afad019e7a702c14562b2e5498646&amp;review=&amp;sale=0&amp;search=1&amp;source=search&amp;spm=a2o4l.store_product.list.i40.651a488742FTZJ&amp;stock=1",
      LazadaHCLink: 
        "https://www.lazada.com.ph/products/alamat-book-2-lumalindaw-alamat-book-series-hardcover-i4117065203-s22808064693.html?c=&amp;channelLpJumpArgs=&amp;clickTrackInfo=query%253A%253Bnid%253A4117065203%253Bsrc%253AlazadaInShopSrp%253Brn%253A748afad019e7a702c14562b2e5498646%253Bregion%253Aph%253Bsku%253A4117065203_PH%253Bprice%253A900%253Bclient%253Adesktop%253Bsupplier_id%253A500163891472%253Bpromotion_biz%253A%253Basc_category_id%253A6915%253Bitem_id%253A4117065203%253Bsku_id%253A22808064693%253Bshop_id%253A1536852&amp;fastshipping=0&amp;freeshipping=0&amp;fs_ab=2&amp;fuse_fs=&amp;lang=en&amp;location=&amp;price=9E%202&amp;priceCompare=skuId%3A22808064693%3Bsource%3Alazada-search-voucher%3Bsn%3A748afad019e7a702c14562b2e5498646%3BoriginPrice%3A90000%3BdisplayPrice%3A90000%3BsinglePromotionId%3A-1%3BsingleToolCode%3A-1%3BvoucherPricePlugin%3A0%3Btimestamp%3A1695769255886&amp;ratingscore=0&amp;request_id=748afad019e7a702c14562b2e5498646&amp;review=&amp;sale=0&amp;search=1&amp;source=search&amp;spm=a2o4l.store_product.list.i40.651a4887aqDh88&amp;stock=1" ,
      ShopeePBLink:
        "https://shopee.ph/Alamat-Book-2-Lumalindaw-(Alamat-Book-Series)-Paper-Back-i.45159795.14099522484?xptdk=78270740-12dc-48d9-8081-5eafcc984985",
      ShopeeHCLink: 
        "https://shopee.ph/Alamat-Book-2-Lumalindaw-(Alamat-Book-Series)-Hardcover-i.45159795.23181160325?xptdk=e0792ddb-5e57-45f9-8077-b4b58d5b82e8" ,
      HotTropiksLink:
        "",
      buttonText: "Buy Now",
      bookPage: "/books/Lumalindaw",
      reviewIndex: 1,
    },
    {
      title: "Taguwasi",
      photo: Book3Teaser,
      shortDescription: "Behold Taguwasi, master of the wind…",
      description:
        "On a nameless mountaintop on the northernmost tip of the known lands, the Lakandian called Lumalindaw meets another of his kind; Taguwasi, master of the wind. The two champions of the Poons befriend one another as Taguwasi invites Lumalindaw to join him and his family to eat, rest, and recover his strength as he continues his search for the witch Muwan. As was common amongst the pre-colonial peoples of the Philippines, Lumalindaw and Taguwasi's family share tales of their lives and adventures as they feast and chew betel nuts together. Lumalindaw tells the tale of his tragic life and exile from his family while Taguwasi and his family tell him of their shared adventures and destinies. The tale of Taguwasi is an adventure story taken from the different points of view of Taguwasi's wife Innawagan, her three brothers, the two magical kumaw birds, the anito Bago, and the Lakandian Taguwasi himself. It is a tale of abduction, heartbreak, and triumph as two magically endowed beings called the manulib, take the beautiful Innawagan captive and set the entire tale into motion. Join the Lakandian Taguwasi and his family as they struggle against impossible odds in their fight to win their freedom from that which holds them bound in darkness as well as their past.",
      link: "https://www.amazon.com/Alamat-Book-Taguwasi-Herbert-Leon/dp/B0BW35YF7V",
      LazadaPBLink:
        "https://www.lazada.com.ph/products/alamat-book-3-taguwasi-alamat-book-series-paperback-i4117095797-s22810004541.html?c=&amp;channelLpJumpArgs=&amp;clickTrackInfo=query%253A%253Bnid%253A4117095797%253Bsrc%253AlazadaInShopSrp%253Brn%253A748afad019e7a702c14562b2e5498646%253Bregion%253Aph%253Bsku%253A4117095797_PH%253Bprice%253A650%253Bclient%253Adesktop%253Bsupplier_id%253A500163891472%253Bpromotion_biz%253A%253Basc_category_id%253A6915%253Bitem_id%253A4117095797%253Bsku_id%253A22810004541%253Bshop_id%253A1536852&amp;fastshipping=0&amp;freeshipping=0&amp;fs_ab=2&amp;fuse_fs=&amp;lang=en&amp;location=&amp;price=6.5E%202&amp;priceCompare=skuId%3A22810004541%3Bsource%3Alazada-search-voucher%3Bsn%3A748afad019e7a702c14562b2e5498646%3BoriginPrice%3A65000%3BdisplayPrice%3A65000%3BsinglePromotionId%3A-1%3BsingleToolCode%3A-1%3BvoucherPricePlugin%3A0%3Btimestamp%3A1695769255886&amp;ratingscore=0&amp;request_id=748afad019e7a702c14562b2e5498646&amp;review=&amp;sale=0&amp;search=1&amp;source=search&amp;spm=a2o4l.store_product.list.i40.651a4887WLMJrn&amp;stock=1",
      LazadaHCLink: 
        "https://www.lazada.com.ph/products/alamat-book-3-taguwasi-alamat-book-series-hardcover-i4117254037-s22809961599.html?c=&amp;channelLpJumpArgs=&amp;clickTrackInfo=query%253A%253Bnid%253A4117254037%253Bsrc%253AlazadaInShopSrp%253Brn%253A748afad019e7a702c14562b2e5498646%253Bregion%253Aph%253Bsku%253A4117254037_PH%253Bprice%253A900%253Bclient%253Adesktop%253Bsupplier_id%253A500163891472%253Bpromotion_biz%253A%253Basc_category_id%253A6915%253Bitem_id%253A4117254037%253Bsku_id%253A22809961599%253Bshop_id%253A1536852&amp;fastshipping=0&amp;freeshipping=0&amp;fs_ab=2&amp;fuse_fs=&amp;lang=en&amp;location=&amp;price=9E%202&amp;priceCompare=skuId%3A22809961599%3Bsource%3Alazada-search-voucher%3Bsn%3A748afad019e7a702c14562b2e5498646%3BoriginPrice%3A90000%3BdisplayPrice%3A90000%3BsinglePromotionId%3A-1%3BsingleToolCode%3A-1%3BvoucherPricePlugin%3A0%3Btimestamp%3A1695769255886&amp;ratingscore=0&amp;request_id=748afad019e7a702c14562b2e5498646&amp;review=&amp;sale=0&amp;search=1&amp;source=search&amp;spm=a2o4l.store_product.list.i40.651a4887liWkLD&amp;stock=1" ,
      ShopeePBLink:
        "https://shopee.ph/Alamat-Book-3-Taguwasi-(Alamat-Book-Series)-Paperback-i.45159795.22555668383",
      ShopeeHCLink: 
        "https://shopee.ph/Alamat-Book-3-Taguwasi-(Alamat-Book-Series)-Hardcover-i.45159795.20993411726?xptdk=7893f563-1b08-4102-b7b8-4edbca7d96d1" ,
      HotTropiksLink:
        "",
      buttonText: "Buy Now",
      bookPage: "/books/Taguwasi",
      reviewIndex: 2,
    },
    {
      title: "Urduja",
      photo: Book4,
      shortDescription: "Warrior. Princess. Lakandian!",
      description:
        "Along the shores of the Lingayen Gulf lies the kingdom of Kaboloan, the richest kingdom in the northern lands. Though the kingdom had been blessed with wealth and prosperity for many generations, it is about to be plunged into a war greater than any in recent memory. A war brought about by the greed and ambitions of a few yet nonetheless suffered by many. In the gradual build-up to the oncoming war, a young princess will find herself caught amidst chaos, political intrigue, betrayal, and murders. Her name is Urduja; the daughter of the king of Kaboloan and a Lakandian. But unlike the other Lakandians in the Alamat Book Series, the tale of Urduja is quite unique. Due to circumstances outside her control, Urduja was born and raised as a normal human would. Yet unlike other people, she was gifted with wit and intelligence unparalleled anywhere in the known lands. But when the secrets of her past come to light, Urduja embarks on a quest to regain her birthright as Lakandian and champion of the immortal realm of Kaluwalhatian. Together with her friends from different tribes and peoples, they will battle their way through mythological beasts and monsters as they find a way to rid Kaboloan of the evil invaders that threaten not just her home but all those she holds dear. Set a few years before the events of Lam-ang, this book covers the untold origin of Urduja. A woman destined to be the Great Warrior Queen that the legend speaks of, as well as the Lakandian that will lead all others in the Kanibusanan; the great war that will determine the fates of the three realms.",
      link: "https://www.amazon.com/Alamat-Book-Urduja-ebook/dp/B0CD7XF542?ref_=ast_author_dp",
      LazadaPBLink:
        "https://www.lazada.com.ph/products/alamat-book-4-urduja-alamat-book-series-paperback-i4117097712-s22810049177.html?c=&amp;channelLpJumpArgs=&amp;clickTrackInfo=query%253A%253Bnid%253A4117097712%253Bsrc%253AlazadaInShopSrp%253Brn%253A748afad019e7a702c14562b2e5498646%253Bregion%253Aph%253Bsku%253A4117097712_PH%253Bprice%253A850%253Bclient%253Adesktop%253Bsupplier_id%253A500163891472%253Bpromotion_biz%253A%253Basc_category_id%253A6915%253Bitem_id%253A4117097712%253Bsku_id%253A22810049177%253Bshop_id%253A1536852&amp;fastshipping=0&amp;freeshipping=0&amp;fs_ab=2&amp;fuse_fs=&amp;lang=en&amp;location=&amp;price=8.5E%202&amp;priceCompare=skuId%3A22810049177%3Bsource%3Alazada-search-voucher%3Bsn%3A748afad019e7a702c14562b2e5498646%3BoriginPrice%3A85000%3BdisplayPrice%3A85000%3BsinglePromotionId%3A-1%3BsingleToolCode%3A-1%3BvoucherPricePlugin%3A0%3Btimestamp%3A1695769255886&amp;ratingscore=0&amp;request_id=748afad019e7a702c14562b2e5498646&amp;review=&amp;sale=0&amp;search=1&amp;source=search&amp;spm=a2o4l.store_product.list.i40.651a4887ILV1dJ&amp;stock=1",
      LazadaHCLink: 
        "https://www.lazada.com.ph/products/alamat-book-4-urduja-alamat-book-series-hardcover-i4117252069-s22809916952.html?c=&amp;channelLpJumpArgs=&amp;clickTrackInfo=query%253A%253Bnid%253A4117252069%253Bsrc%253AlazadaInShopSrp%253Brn%253A748afad019e7a702c14562b2e5498646%253Bregion%253Aph%253Bsku%253A4117252069_PH%253Bprice%253A1050%253Bclient%253Adesktop%253Bsupplier_id%253A500163891472%253Bpromotion_biz%253A%253Basc_category_id%253A6915%253Bitem_id%253A4117252069%253Bsku_id%253A22809916952%253Bshop_id%253A1536852&amp;fastshipping=0&amp;freeshipping=0&amp;fs_ab=2&amp;fuse_fs=&amp;lang=en&amp;location=&amp;price=1.05E%203&amp;priceCompare=skuId%3A22809916952%3Bsource%3Alazada-search-voucher%3Bsn%3A748afad019e7a702c14562b2e5498646%3BoriginPrice%3A105000%3BdisplayPrice%3A105000%3BsinglePromotionId%3A-1%3BsingleToolCode%3A-1%3BvoucherPricePlugin%3A0%3Btimestamp%3A1695769255886&amp;ratingscore=0&amp;request_id=748afad019e7a702c14562b2e5498646&amp;review=&amp;sale=0&amp;search=1&amp;source=search&amp;spm=a2o4l.store_product.list.i40.651a4887QijETp&amp;stock=1" ,
      ShopeePBLink:
        "https://shopee.ph/Alamat-Book-4-Urduja-(Alamat-Book-Series)-Paperback-i.45159795.17798950775",
      ShopeeHCLink: 
        "https://shopee.ph/Alamat-Book-4-Urduja-(Alamat-Book-Series)-Hardcover-i.45159795.19384469392" ,
      HotTropiksLink:"",
      buttonText: "Buy Now",
      bookPage: "/books/Urduja",
      reviewIndex: 3,
    },
    {
      title: "Tala",
      photo: Book5,
      shortDescription: "A true Lakandian awakens...",
      description:
        "A mysterious woman appears in a small fishing village in Barangay Rayap on an island in the middle of Pulilan Lake. She has no memory of who she is or where she came from, yet she has the strength of a dozen men and ferocity in battle unheard of from any warrior in the Namayan Territories. Taken in by a kindly old couple and given the name of Mayumi, this enigmatic beauty with the heart of a warrior, tries her best to blend in with the locals as she tries to uncover the secrets of her past. But when darkness arrives in the form of night creatures and a hundred-year-old hukloban, the pull of combat and the need to protect the people around her forces Mayumi to unshackle herself and face the danger head-on. This is a fictionalized story about Tala, one of the three daughters of the all-powerful deity Bathala. Tala’s tale is a story of revenge and self-discovery, marred by death and tragedy. A tale of love and loss that navigates the fine line between what she wants and what is needed from a hero. Join her as she discovers the truth behind her veiled past, her role in the war of the three realms, and her divine heritage as a true Lakandian.",

      link: "https://www.amazon.com/gp/product/B0CQYBSRVL?ref_=dbs_m_mng_rwt_calw_tkin_4&storeType=ebooks&qid=1703804386&sr=8-1",
      LazadaPBLink:
        "",
      LazadaHCLink: 
        "" ,
      ShopeePBLink:
        "",
      ShopeeHCLink: 
        "" ,
      HotTropiksLink:
        "" ,
      buttonText: "Buy Now",
      bookPage: "/books/Tala",
      reviewIndex: 4,
    },
    {
      title: "Lam-Ang Ascension",
      photo: Book6,
      shortDescription: "Love, death, and ascension.",
      description:
        "The adventures of the strongest Lakandian in Kalupaan continue in this second book covering the life of Lam-ang, loosely based on the Ilocano epic, Biag ni Lam-ang. Lam-ang’s story continues several years after he killed the dalaketnon Bumakas and the tragic loss of his anito companion Mangmankik. Our titular hero now has a new companion called Kimat, a former anito of lightning and thunder that merged with the dog's body. Together they rid the jungles and mountain ranges of the Samtoy Nation of all remnants of the dalaketnon and their horde of night creatures. But the dalaketnon leader Sumarang and his Yawà masters continue to plague the world of men with their dark plots and schemes. Unbeknownst to Lam-ang and Kimat, shadows of a new and old threat are creeping back into their otherwise quaint and peaceful life. Not only must Lam-ang overcome these new threats, but he must also grapple with the fact that he is still not a true Lakandian as he navigates through the complexities of life, love, and acceptance. With the number of Lakandians steadily increasing over the years and the looming war of the three realms reaching its crescendo, can Lam-ang be the Lakandian that the Poons of Kaluwalhatian need him to be? Or will his own needs and desires ultimately leave him to fall by the wayside as the Kanibusanan finally erupts throughout the mortal realm?",
      link: "https://a.co/d/1ZPr7Vw",
      LazadaPBLink:
        "",
      LazadaHCLink: 
        "" ,
      ShopeePBLink:
        "",
      ShopeeHCLink: 
        "" ,
      HotTropiksLink:
        "" ,
      buttonText: "Buy Now",
      bookPage: "/books/Tala",
      reviewIndex: 5,
    },
    {
      title: "Kanibusanan",
      photo: Book7,
      shortDescription: "The Beginning of the End.",
      description:
      "The beginning of the end is here! After the massacre of Taguwasi’s family by Sumarang and his Lalad, events have unfolded that will shape the fates of all. With a Balaang Nakar in his possession, Sumarang uses it to find and kill his first Likum which causes turmoil unheard of in the last six hundred years. Thirsting for vengeance for what the Lalad did to him, Taguwasi enlists the aid of the Lakandian Lam-ang to help him hunt them down, not knowing what Sumarang’s real plans are. Meanwhile, as Tala continues her search for her sisters and the elusive Lakandian Lumalindaw, she accidentally runs into a young Ifugao Lakandian called Aliguyon with whom she shares a fraternal bond. But with the murder of a Dian, the world of the Lakandians comes to a screeching halt as a game of cat and mouse ensues between the Lakandians and the Lalad. Will the indomitable mind of the Lakandian Urduja leading the others be enough to stop Sumarang from wiping out all the Dians in Kalupaan? Or will Sumarang continue to be one step ahead of them as he spreads war and chaos to cover his tracks? Like a stone thrown in a pond, the actions of the Lalad ripple throughout the three realms as the Lakandians, Poons, and Yawàs begin executing their plans and machinations just as the Kanibusanan enters its final act.",
      link: "https://a.co/d/1ZPr7Vw",
      LazadaPBLink:
        "",
      LazadaHCLink: 
        "" ,
      ShopeePBLink:
        "",
      ShopeeHCLink: 
        "" ,
      HotTropiksLink:
        "" ,
      buttonText: "Coming Soon",
      bookPage: "/books/Kanibusanan",
      reviewIndex: 6,
    },
  ],
};
